import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3386237e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "filing-container"
};
var _hoisted_2 = ["href"];
var _hoisted_3 = ["href"];
import { ref } from 'vue';
import { websiteFiling, websiteFilingLink, policeFiling, policeFilingLink } from '../../../setting';
export default {
  __name: 'WebsiteFiling',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("a", {
        href: _unref(websiteFilingLink)
      }, _toDisplayString(_unref(websiteFiling)), 9, _hoisted_2), _createElementVNode("a", {
        "class": "police-filing",
        target: "_blank",
        href: _unref(policeFilingLink)
      }, [_createElementVNode("p", null, _toDisplayString(_unref(policeFiling)), 1)], 8, _hoisted_3)]);
    };
  }
};