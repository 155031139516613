const {ref} = require('vue')
module.exports = {
    day: '2021-07-11 09:00:00',
    // 男方信息
    bridegroom: 'LIYU',
    // 中间的消息可以是 love 也可以是 ❤️
    love: '❤',
    // 女方信息
    bride: 'ZHANGYUE',
    // 在一起发生事件的信息
    calendarList: [
        {
            // 日期
            date: '2021-07-11',
            // 事件
            desc: '我们在一起啦'
        },
        {
            date: '2024-04-07',
            desc: '系统上线'
        },    {
            date: '2024-04-08',
            desc: '预测一下要拉2斤屎'
        },    {
            date: '2024-04-09',
            desc: '拉3斤屎'
        },    {
            date: '2024-04-10',
            desc: '拉4斤屎'
        },    {
            date: '2024-05-02',
            desc: '跟邓子第一次通话'
        }
    ],
    // 照片墙的信息
    recordList: [
        {
            // 照片的地址
            src: require('@/assets/hsl00001.jpeg'),
            // 照片的时间
            time: '2024-04-07 17:40:00',
            // 照片的描述
            desc: '桑德'
        },
        {
            src: require('@/assets/hsl00002.jpeg'),
            time: '2024-04-07 17:40:00',
            desc: '快速收拾完毕，准备出门'
        },
        {
            src: require('@/assets/hsl00001.jpeg'),
            time: '2024-04-07 17:40:00',
            desc: '桑德1'
        },
        {
            src: require('@/assets/240501.jpeg'),
            time: '2024-05-01 17:40:00',
            desc: '德王'
        },
        {
            src: require('@/assets/2405011.jpeg'),
            time: '2024-05-01 17:40:00',
            desc: '德王'
        }
    ],
    // tcp备案
    websiteFiling: 'love',
    // tcp备案链接
    websiteFilingLink: 'https://love.liyuchatgpt.info/' ,
    // 公安备案
    policeFiling: 'love 2021-2888',
    // 公安备案链接
    policeFilingLink: 'https://love.liyuchatgpt.info/'
}
